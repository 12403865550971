import { useEffect } from "react";
import "react-notifications-component/dist/theme.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  useEffect(() => {
    window.location.href = "https://nudos.co/";
  }, []);

  return (
    <div className="w-screen h-screen" style={{ backgroundColor: "#070f21" }} />
  );
};

export default App;
